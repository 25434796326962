<template>
    <b-modal
        ref="modalRef"
        title="Import Dataset"
        hide-footer
        @shown="onShown"
        @hidden="onHidden"
    >
        <div class="body d-flex flex-column rounded-lg">
            <div class="d-flex justify-content-around align-items-center">
                <div class="w-25" />
                <button
                    class="action-btn mx-auto"
                    @click="selectDataset"
                >
                    Select Dataset
                </button>
                <div class="w-25 d-flex">
                    <div class="mx-auto p-1 border rounded-sm">
                        {{ focusedDataset + 1 }} / {{ datasets.length }}
                    </div>
                </div>
            </div>
            <div
                ref="datasetsContainer"
                class="
                    w-100 h-100 pb-2 text-nowrap overflow-x-auto
                    overflow-y-hidden
                "
                @mousedown="onMouseDown"
                @scroll="onScroll"
                @mouseup="onMouseUp"
            >
                <div class="w-25 d-inline-block" />
                <DataSet
                    v-for="dataset, i in datasets"
                    :key="dataset.index"
                    ref="datasets"
                    :dataset="dataset"
                    class="
                        w-50 h-100 mx-2 d-inline-block overflow-auto border
                        rounded-lg shadow
                    "
                    @focus="focusDataset(i)"
                />
                <div class="w-25 d-inline-block" />
            </div>
        </div>
    </b-modal>
</template>

<script>
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'
import DataSet from './select-dataset-modal/dataset.vue'

export default {
    name: 'SelectDatasetModal',
    components: {
        DataSet,
    },
    props: {
        minSampleSize: {
            type: Number,
            default: 2,
        },
        filter: {
            type: Function,
            default: () => true,
        },
        errorMessage: {
            type: String,
            default: function () {
                return 'Sample size must be n ≥ ' + this.minSampleSize
            },
        },
    },
    data () {
        return {
            datasets: [],
            focusedDataset: 0,
            mouseDown: false,
            scrolling: false,
        }
    },
    beforeMount () {
        this.initializeDatasets()
    },
    methods: {
        initializeDatasets () {
            const store = localStore.getStore('summarize-data')?.data
            this.datasets = _.filter(store, (dataset) => {
                const dataValues = sData.getDataValues(dataset.items)
                return this.filter(dataset) &&
                    dataValues.length >= this.minSampleSize
            })
        },
        async show () {
            if (this.datasets.length) {
                this.$refs.modalRef.show()
                await this.$nextTick()
                await this.$nextTick() // Second await needed for scrollIntoView
                this.focusDataset(0)
            } else {
                alert(
                    this.errorMessage + '\n\nGo to Summarize Data to edit sets'
                )
            }
        },
        hide () {
            this.$refs.modalRef.hide()
        },
        onShown () {
            window.addEventListener('keydown', this.handleArrowKeys)
        },
        onHidden () {
            window.removeEventListener('keydown', this.handleArrowKeys)
        },
        onMouseDown () {
            this.mouseDown = true
        },
        onScroll () {
            this.scrolling = this.mouseDown
        },
        onMouseUp () {
            this.mouseDown = false
            if (this.scrolling) {
                this.scrolling = false
                this.focusDataset(
                    Math.round(
                        this.$refs.datasetsContainer.scrollLeft /
                        this.$refs.datasetsContainer.scrollWidth *
                        (this.datasets.length + 1)
                    )
                )
            }
        },
        handleArrowKeys (event) {
            if (event.key === 'ArrowLeft') {
                event.preventDefault()
                this.focusDataset(this.focusedDataset - 1)
            } else if (event.key === 'ArrowRight') {
                event.preventDefault()
                this.focusDataset(this.focusedDataset + 1)
            }
        },
        focusDataset (index) {
            index = index < 0 ? 0 : index
            const max = this.datasets.length - 1
            this.focusedDataset = index > max ? max : index
            this.$refs.datasets[this.focusedDataset].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
        },
        selectDataset () {
            this.$emit('select', this.datasets[this.focusedDataset])
            this.hide()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.body {
    height: 81vh;
    background-color: $mainGray;
}
</style>
