<template>
    <div
        class="overflow-hidden"
        @click="$emit('focus')"
    >
        <div class="h-100 d-flex flex-column">
            <div class="dataset-name text-center">
                {{ dataset.name }}
            </div>
            <div class="h-100 overflow-x-hidden overflow-y-auto scrollbar-thin">
                <div
                    v-for="item, i in dataset.items"
                    :key="item.index"
                    class="item w-100 px-1 bg-white d-flex"
                    :class="[i == dataset.items.length - 1 ? '' : 'mb-1']"
                >
                    <b>{{ item.index + 1 }})</b>
                    <div class="w-100 text-center">
                        {{ item.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataSet',
    props: {
        dataset: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.dataset-name {
    line-height: 1.7;
    font-size: 18px;
}
</style>
