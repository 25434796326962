import errorText from '@/js/lib/error-handle-text.js'
import _ from 'lodash'
var sm = require('statistical-methods')
var percentile = require('percentile')

const formatTestData = (data) => {
    var ids = Array.from(Array(_.size(data)).keys())
    const outputData = _.zipWith(ids, data, (id, value) => ({ id, value }))
    return outputData
}

const updateModeOutput = (arrayValues) => {
    const modeOutput = sm.mode(arrayValues)
    return _.isNull(modeOutput) ? 'None' : modeOutput
}

const calculateDataCheck = (data) => {
    var errors = []
    var output = null
    var onlyValuesArr = getDataValues(data)

    if (onlyValuesArr.length === 0) {
        errors.push(errorText.summarizeDataAlert.noVals)
    } else if (onlyValuesArr.length < 2) {
        errors.push(errorText.summarizeDataAlert.lessThanTwoVals)
    } else {
        var meanCalculatedFixed = _.mean(onlyValuesArr)
        var sumCalculatedFixed = sm.sum(onlyValuesArr)

        // checking mean
        if (!Number.isInteger(meanCalculatedFixed)) {
            meanCalculatedFixed = _.mean(onlyValuesArr).toFixed(4)
        }
        // checking sum
        if (!Number.isInteger(sumCalculatedFixed)) {
            sumCalculatedFixed = sm.sum(onlyValuesArr).toFixed(4)
        }

        output = {
            sampleSize: onlyValuesArr.length,
            mean: meanCalculatedFixed,
            stdDev: sm.stddev(onlyValuesArr).toFixed(4),
            min: _.min(onlyValuesArr),
            twentyFifthPercentile: percentile(25, onlyValuesArr).toFixed(4),
            median: sm.median(onlyValuesArr),
            seventyFifthPercentile: percentile(75, onlyValuesArr),
            max: _.max(onlyValuesArr),
            variance: sm.variance(onlyValuesArr).toFixed(4),
            sum: sumCalculatedFixed,
            mode: updateModeOutput(onlyValuesArr),
            range: sm.range(onlyValuesArr).toFixed(4)
        }
    }
    return { output, errors }
}

const getDataValues = (data) => {
    var dataValues = []
    for (var i in data) {
        var tempStr = data[i].value.toString()
        if (!_.isEmpty(tempStr)) {
            dataValues.push(data[i].value)
        }
    }
    var numValues = _.map(dataValues, number => Number(number))
    return numValues
}

const getFrequency = (data) => {
    return _.countBy(getDataValues(data))
}

// Dot plot
const createDataXY = (data) => {
    const Xvalues = getDataX(data)
    const Yvalues = getDataY(data)
    var newObj = []
    for (var v in Xvalues) {
        const tempObj = { x: Xvalues[v], y: Yvalues[v] }
        newObj.push(tempObj)
    }
    return newObj
}

const createDotDataXY = (data) => {
    var dataVals = getFrequency(data)
    var tempData = []
    for (var [key, value] of Object.entries(dataVals)) {
        if (value > 1) {
            for (let j = value - 1; j !== 0; j--) {
                var addedObj = { x: _.toNumber(key), y: j }
                tempData.push(addedObj)
            }
        }
    }
    const dotData = createDataXY(data).concat(tempData)
    return dotData
}

// Box Plot
const getBoxPlotData = (data) => {
    data = _.mapValues(data, _.toNumber)
    const { min, twentyFifthPercentile, median, seventyFifthPercentile, max } = data
    const output = {
        low: min,
        q1: twentyFifthPercentile,
        median,
        q3: seventyFifthPercentile,
        high: max,
    }
    const outputLabels = formatBoxPlotData(_.values(output))
    return { output, outputLabels }
}

const formatBoxPlotData = (data) => {
    const outputData = _.map(data, (v, i) => {
        return {
            x: 0,
            y: v,
            name: v,
            dataLabels: {
                y: (i % 2 === 0 ? 135 : -100)
            }
        }
    })
    return outputData
}

// -- Y functions --
const getDataY = (data) => {
    const yValues = _.values(getFrequency(data))
    return yValues
}

// -- X functions --
const getDataX = (data) => {
    const xValues = (_.keys(getFrequency(data))).map(a => Number(a))
    return xValues
}

export default {
    formatTestData,
    calculateDataCheck,
    createDotDataXY,
    getDataValues,
    getBoxPlotData
}
